import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <nav id="header" className="w-full z-30 top-0 text-white">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">

                <div className="pl-4 flex items-center">
                    <OutboundLink title="Teléfono de EnTTia" className="text-white no-underline hover:no-underline font-bold lg:text-2xl" href="tel:+34937450700">
                        {/* <StaticImage src="../images/logo.png" alt="Logo de EnTTia" width={150} imgStyle={{ objectFit: "contain" }} /> */}

                        <p className="font-light">
                            <FontAwesomeIcon icon={faPhoneAlt} className="motion-safe:animate-wiggle" fixedWidth width={16} height={16} />&nbsp;
                            ¡Llámanos al 93 745 07 00!
                        </p>
                    </OutboundLink>

                </div>

                <div className="block md:hidden pr-4">
                    <button
                        id="nav-toggle"
                        className="flex items-center p-1 bg-indigo-100 ring-2 ring-indigo-400 ring-opacity-50 text-indigo-600 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                    >
                        <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menú</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>

                <div id="nav-content" className={`w-full flex flex-grow justify-content-center items-center md:w-auto mt-2 md:mt-0 text-white p-4 md:p-0 z-20 md:pr-3 ${isMenuOpen ? "" : "hidden"} md:block`}>
                    <ul className="list-reset md:flex justify-end flex-1 items-center">

                        <li className="mr-2">
                            <Link to="/" className="inline-block no-underline hover:border-b hover:border-white py-2 px-4" title="Ir a la página de inicio">
                                Inicio
                            </Link>
                        </li>

                        <li className="mr-2">
                            <Link to="/#productos" className="inline-block no-underline hover:border-b hover:border-white py-2 px-4" title="Ir a la sección de productos de la página de inicio">
                                Productos
                            </Link>
                        </li>

                        <li className="mr-2">
                            <Link to="/#clientes" className="inline-block no-underline hover:border-b hover:border-white py-2 px-4" title="Ir a la sección de clientes de la página de inicio">
                                Nuestros clientes
                            </Link>
                        </li>

                        <li className="mr-2">
                            <div className="py-2 px-4">
                                <Link to="/#contacto" className="mx-auto md:mx-0 hover:underline bg-white text-gray-800 font-bold rounded mt-4 md:mt-0 my-2 py-2 px-4 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out" title="Ir al formulario de contacto de la página de inicio">
                                    <FontAwesomeIcon icon={faEnvelope} className="motion-safe:animate-pulse mr-2 text-gray-800" fixedWidth width={16} height={16} />
                                    Contacto
                                </Link>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>

            <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
        </nav>
    );
};

export default Nav;