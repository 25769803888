import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

type FooterSectionItemProps = {
    children: React.ReactNode,
    href?: string
    title: string
};

const FooterSectionItem = ({
    children,
    href = "#",
    title
}: FooterSectionItemProps) => {
    return (
        <li className="mt-2 mr-2 md:block md:mr-0">
            <OutboundLink href={href} title={title} className="no-underline hover:underline text-gray-800 hover:text-indigo-500" target="_blank" rel="noopener noreferrer">
                {children}
            </OutboundLink>
        </li>
    );
};

type FooterSectionProps = {
    name: string,
    children: React.ReactNode
};

const FooterSection = ({
    name,
    children,
}: FooterSectionProps) => {
    return (
        <div className="p-2">
            <p className="uppercase text-gray-500 md:mb-6">{name}</p>

            <ul className="list-reset mb-6">
                {children}
            </ul>
        </div>
    );
};

const Footer = ({ }) => {

    const mailto = `mailto:info@enttia.com?subject=${encodeURI(`Quiero saber más acerca de cámaras de videovigilancia`)}`;

    return (
        <footer className="bg-white">
            <div className="container mx-auto px-8">
                <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">

                    <FooterSection name="Social">

                        <FooterSectionItem href="https://es-es.facebook.com/enttia3000/" title="Ir al perfil de Facebook de EnTtia">
                            <FontAwesomeIcon icon={faFacebook} className="mx-3" fixedWidth width={16} height={16} />
                            <span>Facebook</span>
                        </FooterSectionItem>

                        <FooterSectionItem href="https://www.twitter.com/enttia" title="Ir al perfil de Twitter de EnTTia">
                            <FontAwesomeIcon icon={faTwitter} className="mx-3" fixedWidth width={16} height={16} />
                            <span>Twitter</span>
                        </FooterSectionItem>

                        <FooterSectionItem href="https://es.linkedin.com/company/enttia" title="Ir al perfil de LinkedIn de EnTTia">
                            <FontAwesomeIcon icon={faLinkedin} className="mx-3" fixedWidth width={16} height={16} />
                            <span>LinkedIn</span>
                        </FooterSectionItem>

                    </FooterSection>

                    <FooterSection name="Nuestra red">
                        <FooterSectionItem href="https://www.enttia.com/" title="Ir a la página de EnTTia">
                            Control de acceso, presencia y biométrico
                        </FooterSectionItem>
                        <FooterSectionItem href="https://www.evolisprint.com/" title="Ir a la página de Evolis Print">
                            Impresoras de tarjetas y accesorios
                        </FooterSectionItem>
                        <FooterSectionItem href="http://surtidorescerveza.com/" title="Ir a la página de Surtidores de cerveza">
                            Surtidores de cerveza
                        </FooterSectionItem>
                    </FooterSection>

                    <FooterSection name="Teléfono">
                        <FooterSectionItem href="tel:(+34)937450700" title="Teléfono de EnTTia">
                            93 745 07 000
                        </FooterSectionItem>
                    </FooterSection>

                    <FooterSection name="Correo electrónico">
                        <FooterSectionItem href={mailto} title="Correo electrónico de EnTTia">
                            info@enttia.com
                        </FooterSectionItem>
                    </FooterSection>

                    <FooterSection name="Dirección">
                        <FooterSectionItem href="https://goo.gl/maps/Fuq9ZaEwuZVzezss9" title="Dirección de EnTTia">
                            <p className="lh-1">Ronda de Ponent, 2</p>
                            <p className="lh-1">08201 - Sabadell (Barcelona)</p>
                        </FooterSectionItem>
                    </FooterSection>

                </div>

                <div className="flex-1">
                    <p className="font-light text-center text-black">
                        &copy; {(new Date()).getFullYear()} EnTTia | <Link to="/aviso-legal" title="Ir a Aviso legal">Aviso legal</Link> | <Link to="/aviso-legal#politica-de-cookies" title="Ir a la política de cookies">Política de cookies</Link>
                    </p>
                </div>

            </div>

        </footer>
    );
};

export default Footer;