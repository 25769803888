import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, article }) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);
    const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
        twitterUsername,
        author,
        defaultCopyright
    } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    };

    if (seo.title.length > 55) {
        throw new Error(`El título no debe exceder los 55 caracteres. Actualmente hay ${seo.title.length} caracteres: ${seo.title}`);
    }

    if (seo.description.length > 160) {
        throw new Error(`El título no debe exceder los 160 caracteres. Actualmente hay ${seo.description.length} caracteres: ${seo.description}`);
    }

    return (
        <Helmet title={seo.title}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {author && <meta name="author" content={author} />}
            {defaultCopyright && <meta name="copyright" content={defaultCopyright} />}

            {/* OG */}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.url && <meta property="og:type" content="website" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}

            {/* Localización */}
            <meta name="zipcode" content="08205" />
            <meta name="country" content="Spain" />
            <meta name="State" content="Catalonia" />
            <meta name="city" content="Sabadell" />

            <meta name="ICBM" content="41.544229712899565, 2.1007554554936134" />
            <meta name="geo.region" content="ES-CT" />
            <meta name="geo.placename" content="Sabadell" />
            <meta name="geo.position" content="41.544229712899565;2.1007554554936134" />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "id": "https://www.enttia.com/#website",
                        "url": "https://www.enttia.com",
                        "name": "EnTTia",
                        "description": "EnTTia es una ingeniería con una naturaleza de negocio altamente relacionada con las tecnologías de la información. EnTTia está especializada en proyectos de seguridad e identificación de personas: videovigilancia, controles de accesos, controles de presencia y controles de producción.",
                        "legalName" : "ENTTIA 3000, S.L.",
                        "logo": "https://www.enttia.com/logo_150x50.png",
                        "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Ronda de Ponent, 2",
                                "addressLocality": "Sabadell",
                                "addressRegion": "CA",
                                "postalCode": "08201",
                                "addressCountry": "Spain"
                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+93 745 07 00",
                            "contactType": "Información, Ventas, Soporte, Comercial",
                            "areaServed" : "ES",
                            "availableLanguage" : ["Español", "Català"]
                        },
                        "sameAs": [
                            "https://enttia.com/",
                            "https://www.enttia.es/",
                            "http://surtidorescerveza.com/",
                            "https://evolisprint.com/"
                        ]
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                                "@id": "${seo.url}/#website",
                                "url": "${seo.url}/",
                                "name": "${seo.title}",
                                "description": "${seo.description}",
                                "inLanguage": "es"
                    }
                `}
            </script>
        </Helmet>
    );
};

export default SEO;

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
    author: PropTypes.string,
    copyright: PropTypes.string
};

SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    article: false,
    author: null,
    copyright: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername,
        author,
        defaultCopyright: copyright
      }
    }
  }
`;