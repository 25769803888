import React, { useEffect, useState } from 'react';
import Nav from "./Nav";
import Footer from "./Footer";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from 'gatsby';
import SEO from "../components/SEO";
import { FabButton, FabContainer, FabDirection, FabPosition } from '../components/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

function SetTrackingCookies(value): void {
    const cookieNames = ["mobotix-online-GA", "mobotix-online-GTAG"];
    cookieNames.forEach(cookieName =>
        Cookies.set(cookieName, value)
    );
}

const Layout = ({ title, children, showNav = true, showFooter = true }) => {
    const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(Cookies.get("analytics") === "true");
    const [shouldShowFab, setShouldShowFab] = useState(isAnalyticsEnabled);

    const fabClassName = "text-white bg-blue-600 hover:bg-blue-400 shadow-xl opacity-75 ring-2 ring-blue-700 ring-opacity-50 hover:border-2 hover:border-white";
    const fabIconSize = "1x";

    return (
        <>
            <SEO
                title={title}
            />

            <div className="bg-gradient-to-b from-indigo-700 via-indigo-300 to-indigo-700">
                {showNav && <Nav />}
                {children}
                {showFooter && <Footer />}

                {process.env.NODE_ENV == "production" &&
                    <CookieConsent
                        style={{
                            opacity: 0.85,
                            position: "sticky"
                        }}
                        location="bottom"
                        buttonText="¡Entendido!"
                        enableDeclineButton
                        declineButtonText="No aceptar"
                        cookieName="mobotix-online-GA"
                        buttonStyle={{
                            color: "whitesmoke",
                            backgroundColor: "#124AA2",
                            opacity: 1
                        }}
                        declineButtonStyle={{
                            color: "whitesmoke",
                            backgroundColor: ""
                        }}
                        onAccept={() => {
                            location.reload();
                        }}
                        onDecline={() => {
                            SetTrackingCookies(false);
                        }}
                    >
                        Este sitio web utiliza cookies propias y de terceros para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. <Link to="/aviso-legal/#politica-de-cookies" target="_blank" title="Ir a la política de cookies">Más información sobre las cookies.</Link>.
                    </CookieConsent>
                }
            </div>

            {shouldShowFab &&
                <FabContainer position={FabPosition.BottomLeft} direction={FabDirection.Up}>

                    <OutboundLink href="tel:+34937450700" title="Llamar a EnTTia">
                        <FabButton className={fabClassName}>
                            <FontAwesomeIcon icon={faPhone} size={fabIconSize} />
                        </FabButton>
                    </OutboundLink>

                    <OutboundLink href="mailto:info@enttia.com" title="Enviar un correo electrónico a EnTTia">
                        <FabButton className={fabClassName}>
                            <FontAwesomeIcon icon={faEnvelope} size={fabIconSize} />
                        </FabButton>
                    </OutboundLink>

                </FabContainer>
            }
        </>
    );
};

export default Layout;
