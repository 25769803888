import React from "react";

type FabButtonProps = {
    className?: string,
    children: React.ReactNode,
    height?: number
    width?: number,
};

const FabButton = ({
    className, children,
    height = 3,
    width = 3
}: FabButtonProps) => {
    return (
        <div
            className={`rounded-full flex items-center justify-center ${className}`}
            style={{
                height: `${height}em`,
                width: `${width}em`,
            }}
        >
            {children}
        </div >
    );
};

export enum FabPosition {
    TopLeft, TopCenter, TopRight,
    MidLeft, MidCenter, MidRight,
    BottomLeft, BottomCenter, BottomRight,
};

export enum FabDirection {
    Up, Down, Left, Right
}

type FabContainerProp = {
    children: React.ReactNode,
    position?: FabPosition,
    direction?: FabDirection
};

const FabContainer = ({
    children,
    position = FabPosition.BottomRight,
    direction = FabDirection.Up
}: FabContainerProp) => {
    let positionClassName = "";
    switch (position) {
        /** TOP */
        case FabPosition.TopLeft: positionClassName = "top-2 left-2"; break;
        case FabPosition.TopCenter: positionClassName = "top-2 left-1/2 -translate-x-1/2"; break;
        case FabPosition.TopRight: positionClassName = "top-2 right-2"; break;

        /** MID */
        case FabPosition.MidLeft: positionClassName = "top-1/2 -translate-y-1/2 left-2"; break;
        case FabPosition.MidCenter: positionClassName = "top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"; break;
        case FabPosition.MidRight: positionClassName = "top-1/2 -translate-y-1/2 right-2"; break;

        /** BOTTOM */
        case FabPosition.BottomLeft: positionClassName = "bottom-2 left-2"; break;
        case FabPosition.BottomCenter: positionClassName = "bottom-2 left-1/2 -translate-x-1/2"; break;
        case FabPosition.BottomRight: positionClassName = "bottom-4 right-4"; break;
    }

    let directionClassName = "";
    switch (direction) {
        case FabDirection.Left: directionClassName = "flex-row flex-row-reverse"; break;
        case FabDirection.Right: directionClassName = "flex-row"; break;

        case FabDirection.Up: directionClassName = "flex-col flex-col-reverse"; break;
        case FabDirection.Down: directionClassName = "flex-col"; break;
    }

    return (
        <div className={`fixed flex gap-2 ${positionClassName} ${directionClassName}`} style={{ zIndex: 1000 }}>
            {children}
        </div>
    );
};

export {
    FabButton,
    FabContainer
};